import React from 'react';
import { Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { StaticQuery, graphql } from 'gatsby';

import { rhythm, scale } from '../../utils/typography';
import Social from '../social';
import styles from './layout.module.css';

import CustomLink from '../link';
import PreWithCopy from '../prev-with-copy';

const mdxComponents = {
  Link: CustomLink,
  pre: PreWithCopy
};

const toSocialLinks = links =>
  ['linkedin', 'twitter', 'stackoverflow', 'github', 'rss'].map(key => {
    return {
      link: links[key],
      name: key
    };
  });

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;

    const socialLinks = toSocialLinks(this.props.site.siteMetadata.social);

    const isRoot = location.pathname === rootPath;

    const header = isRoot ? (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    ) : (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          margin: 0
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    );
    return (
      <div
        style={{
          maxWidth: rhythm(24),
          padding: `${rhythm(1.07)} ${rhythm(3 / 4)} 0`
        }}
        className={styles.wrapper}
      >
        <header className={isRoot ? '' : styles.header}>{header}</header>
        <main
          className={styles.content}
          ref={element => (this.$mainRef = element)}
        >
          <MDXProvider components={mdxComponents}>{children}</MDXProvider>
        </main>
        <footer
          className={styles.footer}
          style={{ fontSize: rhythm(0.43), marginTop: rhythm(4) }}
        >
          <Social medias={socialLinks} />
        </footer>
      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={layoutQuery}
    render={queryPorps => <Layout {...queryPorps} {...props} />}
  />
);

const layoutQuery = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        social {
          twitter
          github
          linkedin
          stackoverflow
          rss
        }
      }
    }
  }
`;
