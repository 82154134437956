import React, { useRef, useState } from 'react';

import styles from './index.module.css';

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const RESET_DURATION = 3000;

const Pre = props => {
  const { children, ...rest } = props;

  const canCopy = !props.className.endsWith('-text');

  const preRef = useRef(null);

  const [isCopied, setIsCopied] = useState(false);

  const onCopy = () => {
    const [codeEle] = preRef.current.getElementsByTagName('code');
    copyToClipboard(codeEle.textContent);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), RESET_DURATION);
  };

  return (
    <pre {...rest} ref={preRef}>
      {canCopy ? (
        <div className={styles.Pre__copy}>
          <button onClick={onCopy} className={styles.Pre__copyBtn}>
            {isCopied ? `Copied!` : 'Copy'}
          </button>
        </div>
      ) : null}
      {children}
    </pre>
  );
};

export default Pre;
