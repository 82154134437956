import React from 'react';

const Link = props => {
  return <a {...props}>{props.children}</a>;
};

Link.defaultProps = {
  target: '_blank',
  rel: 'noreferrer noopener'
};

export default Link;
